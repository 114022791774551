export function atc() {
  $('[class*="c-headingAtc"]').each(function(){
    let txt = $(this).text();
    $('<dd class="p-interviewOutline_title">'+ txt +'</dd>').appendTo('.p-interviewOutline');
  });
  $('.c-tag--green').each(function(){
    let $href = $(this).attr('href');
    if(location.href.match($href)) {
    $(this).addClass('is-active');
    $('.c-tag--all').removeClass('is-active');
    }
  });
}
