export function header() {
  $('.l-headerMenuBtn').on('click', function(){
    $(this).toggleClass('is-open');
    $('.l-header').toggleClass('is-open');
    if($(this).hasClass('is-open')) {
      $('.l-headerNav').fadeIn();
    } else if(!($(this).hasClass('is-open'))){  
      $('.l-headerNav').fadeOut();
    }    
  })
}
